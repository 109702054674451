import Button from "../../Components/Button";
import Header from "../../Components/Header/Header";
import MoneyList from "../../Components/MoneyList";
import { useNavigate } from "react-router-dom";

function MoneyCart() {
    const navigate = useNavigate();
    function handleSelect(mode){
       return mode === 'back' ? navigate(-1) : null;
    }
    return (
        <div className="money-cart">
            <Button buttonClass={'back-button'} onSelect={()=>handleSelect('back')}>Back</Button>
            <MoneyList />
            </div>);
}
export default MoneyCart;