import React from 'react';
import styles from './CreditBills.module.css';
import { useNavigate } from 'react-router-dom';
import CreditStatusBox from './CreditStatusBox';
import CreditBillsTable from './CreditBillsTable';

function CreditBills() {
    const navigate = useNavigate();
    return (
        <>
            <div className={styles.container}>
                <div className={styles.headerRow}>
                    <h1 className={styles.heading}>Credit Management System</h1>
                    <div className={styles.creditStatus}>
                        <h2>Credit Status for</h2>
                        <select className={styles.dropdown}>
                            <option>Salesman 1</option>
                            <option>Salesman 2</option>
                            <option>Salesman 3</option>
                            <option>Salesman 4</option>
                        </select>
                        <button className={styles.goButton}>Show</button>
                    </div>
                    <div className={styles.buttonGroup}>
                        <button className={styles.button} onClick={() => navigate('/supervisordashboard/issuebills')}>Bill Issuance</button>
                        <button className={styles.button} onClick={() => navigate('/supervisordashboard/clearbills')}>Bill Clearance</button>
                    </div>
                </div>
                <CreditStatusBox />
                <CreditBillsTable type={'supervisor'}/>
            </div>
        </>
    );
}

export default CreditBills;
