import { configureStore } from '@reduxjs/toolkit';
import supervisorReducer from './supervisor-slice.js';
import deliveryBoyReducer from './deliveryExecutive-slice.js';
import roleReducer from './role-slice.js';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedSupervisorReducer = persistReducer(persistConfig, supervisorReducer);
const persistedDeliveryBoyReducer =persistReducer(persistConfig,deliveryBoyReducer);
const persistedRoleReducer = persistReducer(persistConfig,roleReducer);

const store = configureStore({
  reducer: {
    supervisor: persistedSupervisorReducer, // Use the persisted reducer here
    deliveryBoy: persistedDeliveryBoyReducer,
    role:persistedRoleReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

