import Cart from "../../Components/ReturnCart/Cart";
import CartTemp from "../../Components/ReturnCart/CartTemp";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";

function ExpiryCart() {
    const navigate = useNavigate();
    function handleSelect(mode) {
        return mode === 'back' ? navigate(-1) : null;
    }
    return (
        <div>
            <Button buttonClass={'back-button'} onSelect={() => handleSelect('back')}>Back</Button>
            {/* <Cart cartMode={'expiry'} isSupervisor={false} /> */}
            <CartTemp cartMode={'expiry'} />
        </div>
    );
};
export default ExpiryCart;