import React, { useEffect, useState } from 'react';
import classes from './ShopListTab.module.css';
import ShopList from '../ShopList';
import ShopHeader from '../ShopHeader';
import { useSelector } from 'react-redux';
import { GET_DISPATCHED_SUMMARIES } from '../../enums/routes';

const SupervisorSummaryListTab = () => {
    const [shopData, setShopData] = useState([]);
    const [activeTab, setActiveTab] = useState('shoplist');
    //const [itemData, setItemData] = useState([]);
    const [error, setError] = useState(null);
    const brandId = useSelector((state) => state.supervisor.selectedBrand.id);
    const deliveryBoyId = useSelector((state) => state.supervisor.selectedSummary.deliveryBoyId);
    const Uuid = useSelector((state) => state.supervisor.selectedSummary.uuid);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let apiUrl=`${GET_DISPATCHED_SUMMARIES}`;
                let queryParams = {
                    brandId: brandId,
                    uuid: Uuid,
                    deliveryBoyId: deliveryBoyId
                };

                // Add query parameters to the URL
                const queryString = new URLSearchParams(queryParams).toString();
                const urlWithQuery = apiUrl + (queryString ? `?${queryString}` : '');
                const response = await fetch(urlWithQuery, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();
                if (result.success) {
                    setShopData(result.data.shopData)
                }else {
                    setError(result.message);
                    console.error(result.message);
                }
            } catch (error) {
                setError('Error fetching data');
                console.error('Error fetching data:', error);
            }
        };

            fetchData();
    }, [token, brandId, Uuid, deliveryBoyId]);
    return (
        <>
            <div className={classes.tabContainer}>
                <div className={classes.tabHeader}>
                    <div
                        className={`${classes.tab} ${activeTab === 'shoplist' ? classes.active : ''}`}
                        onClick={() => setActiveTab('shoplist')}
                    >
                        Shoplist
                    </div>
                    {/* {role === 'deliveryBoy' && <div
                        className={`${classes.tab} ${activeTab === 'itemlist' ? classes.active : ''}`}
                        onClick={() => setActiveTab('itemlist')}
                    >
                        Item List
                    </div>} */}
                </div>
                <div className={classes.tabContent}>
                    {activeTab === 'shoplist' &&
                        <>
                            <ShopHeader type='shop' />
                            <ShopList type='shop' data={shopData} />
                        </>
                    }
                    {/* {activeTab === 'itemlist' && role === 'deliveryBoy' &&
                        <>
                            <ShopHeader type='item' />
                            <ShopList type='item' data={itemData} />
                        </>
                    } */}
                </div>
            </div>
        </>

    );
};

export default SupervisorSummaryListTab;
