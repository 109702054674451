import React, { useState } from 'react';
import classes from './BillCombination.module.css';
import { useNavigate,useLocation } from 'react-router-dom';
import Button from '../../Components/Button';
import CashOnlineModal from '../../Components/CashOnlineChequeModal/CashOnlineModal';
import CancelModal from '../../Components/CancelModal/CancelModal';
import CombinationModal from '../../Components/CombinationModal/CombinationModal';

const BillCombination = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data= location.state;
    // const type = location.state?.type;
    const [isModalOpen, setModalOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isCombinationModal, setIsCombinationModalOpen]= useState(false);
    const [paymentMode, setPaymentMode] = useState('');

    const openModal = (mode) => {
        setModalOpen(true);
        setPaymentMode(mode)
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const closeCancelModal = () => {
        setIsCancelModalOpen(false);
    }

    return (
        <>
            <div className={classes.container}>
                {isModalOpen && <CashOnlineModal closeModal={closeModal} paymentMode={paymentMode} data={data}/>}
                <div className={classes.header}>
                    <Button buttonClass={'back-button'} onSelect={() => navigate(-1)}>Back</Button>
                    <div className={classes.billInfo}>
                        <div className={classes.billDetail}>Bill Number: {data.item.billNo}</div>
                        <div className={classes.billDetail}>Shop Name: {data.item.customerName}</div>
                        <div className={classes.billDetail}>Amount: {data.item.billAmount}</div>
                    </div>
                </div>
                <div className={classes.buttons}>
                    <button className={classes.button} onClick={() => openModal('cash')}>Full Cash</button>
                    {/* {type !== 'salesman' && <button className={classes.button} onClick={() => openModal('sign')}>Full Sign</button>} */}
                    <button className={classes.button} onClick={() => openModal('fullSignCredit')}>Full Sign</button>
                    <button className={classes.button} onClick={() => openModal('online')}>Full Online</button>
                    {/* {type !== 'salesman' && <button className={classes.button} onClick={() => setIsCancelModalOpen(true)}>Full Cancel</button>} */}
                    <button className={classes.button} onClick={() => setIsCancelModalOpen(true)}>Full Cancel</button>
                    {isCancelModalOpen && <CancelModal onClose={closeCancelModal} data={data}/>}
                    <button className={classes.button} onClick={() => openModal('fullCheque')}>Full Cheque</button>
                    {/* <button className={classes.button} onClick={()=>setIsCombinationModalOpen(true)}>Combination</button> */}
                    {/* {type === 'salesman' && <button className={classes.button}>Bill Return</button>} */}
                    {/* {isCombinationModal && <CombinationModal onClose={()=>setIsCombinationModalOpen(false)}/>} */}
                </div>
            </div>
        </>
    );
};

export default BillCombination;