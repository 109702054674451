// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import "./App.css";
// import DeliveryBoyDashboard from "./Pages/DeliveryBoy/DeliveryBoyDashboard";
// import MoneyCart from "./Pages/DeliveryBoy/MoneyCart";
// import ReturnCart from "./Pages/DeliveryBoy/ReturnCart";
// import ExpiryCart from "./Pages/DeliveryBoy/ExpiryCart";
// import BillCombination from "./Pages/BillCombination/BillCombination";
// import SuperviorDashboard from "./Pages/Supervisor/SuperisorDashboard";
// import IssueBills from "./Pages/Supervisor/Credit/IssueBills";
// import ClearBills from "./Pages/Supervisor/Credit/BillClearence";
// import SalesmanDashboard from "./Pages/Salesman/SalesmanDashboard";
// import RootLayoutDelivery from "./Pages/DeliveryBoy/RootLayoutDelivery";
// import RootLayoutSupervisor from "./Pages/Supervisor/RootLayoutSupervisor";
// import LogInPage, { action as authAction } from "./Pages/LogIn/LogInPage";
// import { checkAuthLoader, checkAuthLoaderCommon, checkAuthLoaderDelivery, checkAuthLoaderSupervisor } from "./utils/authCustom";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <RootLayoutDelivery />,
//     children: [
//       { path: "login", element: <LogInPage />,},
//       { index: true, element: <DeliveryBoyDashboard />, loader:checkAuthLoaderDelivery},
//       { path: "money", element: <MoneyCart />, loader:checkAuthLoaderCommon},
//       { path: "return", element: <ReturnCart />,loader:checkAuthLoaderCommon },
//       { path: "expiry", element: <ExpiryCart />,loader:checkAuthLoaderCommon },
//       { path: "billcombination", element: <BillCombination />,loader:checkAuthLoaderDelivery },
//     ],
//   },
//   {
//     path: "/supervisordashboard",
//     element: <RootLayoutSupervisor />,
//     children: [
//       { index: true, element: <SuperviorDashboard /> ,loader:checkAuthLoaderSupervisor},
//       { path: "/supervisordashboard/issuebills", element: <IssueBills />,loader:checkAuthLoaderSupervisor },
//       { path: "/supervisordashboard/clearbills", element: <ClearBills />,loader:checkAuthLoaderSupervisor },
//     ],
//   },
//   // { path: "/salesmandashboard", element: <SalesmanDashboard />,loader:checkAuthLoader },
// ]);

// function App() {
//   return <RouterProvider router={router} />;
// }

// export default App;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import DeliveryBoyDashboard from "./Pages/DeliveryBoy/DeliveryBoyDashboard";
import MoneyCart from "./Pages/DeliveryBoy/MoneyCart";
import ReturnCart from "./Pages/DeliveryBoy/ReturnCart";
import ExpiryCart from "./Pages/DeliveryBoy/ExpiryCart";
import BillCombination from "./Pages/BillCombination/BillCombination";
import SuperviorDashboard from "./Pages/Supervisor/SuperisorDashboard";
import IssueBills from "./Pages/Supervisor/Credit/IssueBills";
import ClearBills from "./Pages/Supervisor/Credit/BillClearence";
import SalesmanDashboard from "./Pages/Salesman/SalesmanDashboard";
import RootLayoutDelivery from "./Pages/DeliveryBoy/RootLayoutDelivery";
import RootLayoutSupervisor from "./Pages/Supervisor/RootLayoutSupervisor";
import LogInPage, { action as authAction } from "./Pages/LogIn/LogInPage";
import { checkAuthLoader, checkAuthLoaderCommon, checkAuthLoaderDelivery, checkAuthLoaderSupervisor } from "./utils/authCustom";
import { QueryClient,QueryClientProvider } from "@tanstack/react-query";


const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayoutDelivery />,
    children: [
      { path: "login", element: <LogInPage /> },
      { index: true, element: <DeliveryBoyDashboard />, loader: checkAuthLoaderDelivery },
      { path: "money", element: <MoneyCart />, loader: checkAuthLoaderCommon },
      { path: "return", element: <ReturnCart />, loader: checkAuthLoaderCommon },
      { path: "expiry", element: <ExpiryCart />, loader: checkAuthLoaderCommon },
      { path: "billcombination", element: <BillCombination />, loader: checkAuthLoaderDelivery },
    ],
  },
  {
    path: "/supervisordashboard",
    element: <RootLayoutSupervisor />,
    children: [
      { index: true, element: <SuperviorDashboard />, loader: checkAuthLoaderSupervisor },
      { path: "/supervisordashboard/issuebills", element: <IssueBills />, loader: checkAuthLoaderSupervisor },
      { path: "/supervisordashboard/clearbills", element: <ClearBills />, loader: checkAuthLoaderSupervisor },
    ],
  },
  // { path: "/salesmandashboard", element: <SalesmanDashboard />, loader: checkAuthLoader },
]);

function App() {
  return (
    // Wrap your app with QueryClientProvider and pass the queryClient instance
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
