import React from 'react';
import classes from "../Assets/CSS/ShopHeader.module.css";
const ShopHeader = ({ type }) => {
    return (
        <div className={classes.tablecontainer}>
            <div className={classes.shoplist}>
                <table>
                    <thead>
                        <tr>
                            <th>{type === 'shop' ? 'Bill No.' : 'S.No'}</th>
                            <th>{type === 'shop' ? 'Shop Name' : 'Item Name'}</th>
                            <th>{type === 'shop' ? 'Amount' : 'Qty'}</th>
                            <th>{type === 'shop' ? 'Status' : 'Amount'}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    );
};

export default ShopHeader;