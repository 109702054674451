import React, { useState, useEffect } from 'react';
import styles from './ReportPage.module.css';
import TabComponent from '../../../Components/Tabs/TabComponent';
import { setSupervisorData, setSelectedBrand } from '../../../store/supervisor-slice';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { setSummaries,setSelectedSummary } from '../../../store/supervisor-slice';
import { setDeliveryBoys } from '../../../store/supervisor-slice';
import 'react-toastify/dist/ReactToastify.css';
import { ORDER_UPLOAD, GET_SUMMARIES_AND_DELIVERY_BOYS } from '../../../enums/routes';
import GlobalLoader from '../../../Assets/Loader/GlobalLoader';
import { setRole } from '../../../store/role-slice';
import SupervisorSummaryListTab from '../../../Components/Tabs/SupervsiorSummaryListTab';

const ReportPage = ({ type }) => {
  const selectedBrand = useSelector((state) => state.supervisor.selectedBrand);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isSummaryEmpty, setIsSummaryEmpty] = useState(false);
  const [summaryMessage, setSummaryMessage] = useState('');
  const [selectedSummaryId, setSelectedSummaryId] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.supervisor.brands);
  const supervisorDetails = useSelector((state) => state.supervisor.supervisorDetails);
  const token = useSelector((state) => state.supervisor.token);
  const summaries = useSelector((state) => state.supervisor.selectedBrand.summaries);  // Access summaries from selected brand

  useEffect(() => {
    if (location.state) {
      const { supervisorDetails, brands, token, role } = location.state;
      dispatch(setSupervisorData({ supervisorDetails, brands, token }));
      dispatch(setRole(role))
    }
  }, [location.state, dispatch]);

  const handleBrandSelect = async (e) => {
    const selectedBrandId = e.target.value;
    const selectedBrandName = brands.find(brand => brand.id === selectedBrandId)?.name;

    const selectedBrand = {
      id: selectedBrandId,
      name: selectedBrandName,
      summaries: []  // Initialize empty summaries for this brand
    };

    dispatch(setSelectedBrand(selectedBrand));

    // Show loader
    setLoading(true);

    try {
      const response = await fetch(`${GET_SUMMARIES_AND_DELIVERY_BOYS}${selectedBrandId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();

      if (response.status === 201) {
        setIsSummaryEmpty(true);
        setSummaryMessage(data.message);
      } else if (data.success) {
        setIsSummaryEmpty(false);
        // Store summaries inside the brand
        dispatch(setSummaries(data.data.summaries));
      }
    } catch (error) {
      toast.error('Error fetching summaries and delivery boys:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async () => {
    const fileInput = document.querySelector(`.${styles.fileInput}`);
    const file = fileInput.files[0];

    if (!file) {
      toast.error('Please upload a file before proceeding.');
      return;
    }

    if (!selectedBrand.id) {
      toast.error('Please select a brand before uploading a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('brandId', selectedBrand.id);

    setLoading(true);
    try {
      const response = await fetch(ORDER_UPLOAD, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`  // Add the token in the headers
        }
      });

      if (response.status === 400) {
        return toast.error('This File has already been uploaded');
      }
      if (response.ok) {
        setFileUploaded(true);
        toast.success('File uploaded successfully!');
      } else {
        toast.error('Failed to upload file. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred during file upload.');
    } finally {
      setLoading(false)
    }
  };

  if (!supervisorDetails) {
    return <GlobalLoader />;
  }

  return (
    <>
      {loading ? <GlobalLoader /> : <>
        <ToastContainer />
        {selectedBrand && selectedBrand.id && brands.length > 0 && (
          <div className={styles.brandSelection}>
            <select
              value={selectedBrand.id}
              onChange={handleBrandSelect}
              className={styles.dropdown}
            >
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={styles.reportPage}>
          {selectedBrand && selectedBrand.id && fileUploaded && (
            <h1 className={styles.heading}>
              {/* {type === 'report' ? 'Report' : 'Current Status'} */}
            </h1>
          )}
          {!selectedBrand.id && type === 'currentstatus' && (
            <div className={styles.brandSelection}>
              <p>Select Brand to Continue:</p>
              <select className={styles.dropdown} onChange={handleBrandSelect} value={selectedBrand.id || ''}>
                <option value="">Select Brand</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedBrand.id && type !== 'report' && (
            <div className={styles.uploadSection}>
              <p>Upload Excel Sheet:</p>
              <input type="file" className={styles.fileInput} />
              <button className={styles.goButton} onClick={handleFileUpload}>
                Go
              </button>
            </div>
          )}

          {(selectedBrand.id || type === 'report') && (
            <>
              <div className={styles.reportSummary}>
                {!isSummaryEmpty && <p className={styles.summaryText}>
                  {type === 'currentstatus'
                    ? 'Check Delivery Status for:'
                    : 'Take report for summary:'}
                </p>}
                {!isSummaryEmpty && <div className={styles.summarySelection}>
                  <select
                    className={styles.dropdown}
                    value={selectedSummaryId}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      setSelectedSummaryId(selectedId);
                      const foundSummary = summaries.find((summary) => summary._id === selectedId);
                      dispatch(setSelectedSummary(foundSummary));
                    }}
                  >
                    <option value="">Select Summary</option>
                    {summaries.map((summary) => (
                      <option key={summary._id} value={summary._id}>
                        {summary.summaryName}
                      </option>
                    ))}
                  </select>
                  {/* <button className={styles.goButton}>Go</button> */}
                </div>}
              </div>
              {/* Botton Section */}
              {isSummaryEmpty ? (
                <div >
                  <p className={styles.boldText}>{summaryMessage}</p>
                </div>
              ) : (
                selectedSummaryId && (
                  <div>
                    {summaries.find((summary) => summary._id === selectedSummaryId)?.isDispatchedBySupervisor === false ? (
                      <p className={styles.boldText}>
                        {summaries.find((summary) => summary._id === selectedSummaryId)?.summaryName} is not dispatched yet.
                      </p>
                    ) : (
                      <>
                        {/* Existing Tabs and Content */}
                        <div className={styles.tabsRow}>
                          <TabComponent dashboardType={'deliveryboy'} />
                        </div>
                        <div className={styles.contentRow}>
                          <div className={styles.tableContainer}>
                            <SupervisorSummaryListTab />
                            {/* <ShopHeader type="shop" />
                            <ShopList type="shop" /> */}
                          </div>
                          {type === 'report' && (
                            <div className={styles.buttonWrapper}>
                              <button className={styles.fullReportButton}>
                                Full report of summary taken
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )
              )}
            </>
          )}
        </div>
      </>}
    </>
  );
};

export default ReportPage;

