import styles from './CreditStatusBox.module.css'
function CreditStatusBox(){
    return(
        <div className={styles.boxes}>
                    <div className={styles.boxContainer}>
                        <h3 className={styles.boxTitle}>Total Credit</h3>
                        <div className={styles.box}>
                            <p>Total Amount: 13423</p>
                            <p>Number of Bills: 5</p>
                        </div>
                    </div>
                    <div className={styles.boxContainer}>
                        <h3 className={styles.boxTitle}>Current Credit</h3>
                        <div className={styles.box}>
                            <p>Total Amount: 13423</p>
                            <p>Number of Bills: 5</p>
                        </div>
                    </div>
                    <div className={styles.boxContainer}>
                        <h3 className={styles.boxTitle}>Getting Old</h3>
                        <div className={styles.box}>
                            <p>Total Amount: 13423</p>
                            <p>Number of Bills: 5</p>
                        </div>
                    </div>
                    <div className={styles.boxContainer}>
                        <h3 className={styles.boxTitle}>Old</h3>
                        <div className={styles.box}>
                            <p>Total Amount: 13423</p>
                            <p>Number of Bills: 5</p>
                        </div>
                    </div>
                    <div className={styles.boxContainer}>
                        <h3 className={styles.boxTitle}>Dead</h3>
                        <div className={styles.box}>
                            <p>Total Amount: 13423</p>
                            <p>Number of Bills: 5</p>
                        </div>
                    </div>
                </div>
    )
}
export default CreditStatusBox;