import React from 'react';
import classes from './ReturnSummary.module.css';

const ReturnSummary = ({ onBack, isOpen, role, itemList}) => {
    if (!isOpen) return null;
    return (
        <div className={classes.modal}>
            <div className={classes.modalcontent}>
                <div className={classes.modalheader}>
                    <h2 >Return Summary</h2>
                    <button className={classes.backbutton} onClick={onBack}>Back</button>
                </div>
                <div className={classes.modalbody}>
                    <table>
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                {/* {role === 'supervisor' && (
                                 <>
                                     <th>Received Qty</th>
                                     <th>Shortage</th>
                                 </>
                                 )} */}
                            </tr>
                        </thead>
                        <tbody>
                            {itemList.items.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.itemName}</td>
                                    <td>{item.totalQuantity}</td>
                                    {/* {isSupervisor && (
                                        <>
                                        <td><input type='number' className={classes.inputbox}/></td>
                                        <td><input type='number' className={classes.inputbox}/></td>
                                        </>
                                    )} */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {role === 'supervisor' && <button className={classes.submitButton}>Submit Return Report</button>}
                </div>
            </div>
        </div>
    );
};

export default ReturnSummary;


