import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import classes from './ReturnCart.module.css';
import { dummyReturnCartData } from '../../Data/ReturnCartData';
import ReturnSummary from './ReturnSummary';
import { useSelector } from 'react-redux';
import { EXPIRY_CART, RETURN_CART } from '../../enums/routes';
import GlobalLoader from '../../Assets/Loader/GlobalLoader';

const data = dummyReturnCartData;

const CartTemp = ({ cartMode }) => {
    const navigate = useNavigate();
    const role = useSelector((state) => state.role.role);
    const [isReturnSummaryOpen, setIsReturnSummaryOpen] = useState(false);
    const [showShortageModal, setShowShortageModal] = useState(false);
    const [cartData, setCartData] = useState(null);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const deliveryBoyId = useSelector((state) =>
        role === 'supervisor' ? state.supervisor.selectedSummary.deliveryBoyId : state.deliveryBoy.deliveryBoyDetails.id
    );

    const handleBack = () => {
        setIsReturnSummaryOpen(false);
    };

    const summaryHandler = () => {
        setIsReturnSummaryOpen(true);
    };

    const handleSelect = (mode) => {
        if (mode === 'back') navigate(-1);
    };

    //Fetch API data
    useEffect(() => {
        const fetchCartData = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            let apiEndPoints = RETURN_CART;
            if(cartMode === 'expiry'){
                apiEndPoints = EXPIRY_CART;
            }
            try {
                const response = await fetch(`${apiEndPoints}?deliveryBoyId=${deliveryBoyId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                });
                const result = await response.json();
                if (result.success) {
                    setCartData(result.data);
                } else {
                    console.error('Error fetching return summary:', result.message);
                    setError(result.message);
                }
            } catch (error) {
                console.error('API error:', error);
                //setError(error);
            }finally{
                setLoading(false);
            }
        };

        fetchCartData();
    }, [deliveryBoyId]);
    if(loading){
        return <GlobalLoader />
    }
    if (!cartData) {
        return <h3 className={classes.error}>No Data Found!</h3>
    }
    if (error) {
        return error;
    }

    return (
        <div className={classes.returnsummarycontainer}>
            <h1 className={classes.mainheading}>{cartMode === 'return' ? `Return Value = 0` : 'Expiry Returns'}</h1>
            {cartMode === 'expiry' && <h2 className={classes.exptotal}>{`Total = 0`}</h2>}
            <div className={classes.summaryheader}>
                {/* <Button buttonClass={'back-button'} onSelect={() => handleSelect('back')}>Back</Button> */}
                {cartMode === 'return' && <span className={classes.returnsummary} onClick={summaryHandler}>Return Summary</span>}
            </div>
            {cartMode === 'expiry' && role === 'supervisor' ?
                <div className={classes.expiryContainer}>
                    <p className={classes.verifyText}>{`Verify that MRP of expiry item is 0`}</p>
                    <button className={classes.verifyButton}>Yes Verified</button>
                    {/* <button className={classes.shortageButton} onClick={() => setShowShortageModal(true)}>No Report Shortage</button> */}

                    {/* {showShortageModal && (
                        <div className={classes.shortageModal}>
                            <form className={classes.shortageForm}>
                                <div className={classes.formGroup}>
                                    <label>MRP Received:</label>
                                    <input type="number" name="mrpReceived" className={classes.input} />
                                </div>
                                <div className={classes.formGroup}>
                                    <label>Shortage Equals:</label>
                                    <input type="number" name="shortage" className={classes.input} />
                                </div>
                                <button type="submit" className={classes.submitButton}>Submit Shortage Report</button>
                            </form>
                        </div>
                    )} */}
                </div>
                :
                <div className={classes.summarycontainer}>
                    <table className={classes.summarytable}>
                        <thead>
                            <tr>
                                <th>Bill No</th>
                                <th>Shop Name</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartData.orders.map((item, index) => (
                                 <tr key={index}>
                                     <td>{item.billNo}</td>
                                     <td>{item.customerName}</td>
                                     <td>{item.totalAmountOfOrder}</td>
                                 </tr>
                             ))}
                        </tbody>
                    </table>
                </div>
            }
            {role === 'supervisor' && cartMode === 'return' && <button className={classes.nextButton} onClick={summaryHandler}>Next - Check Items</button>}
            <ReturnSummary isOpen={isReturnSummaryOpen} onBack={handleBack} role={role} itemList={cartData}/>
        </div>
    );
};

export default CartTemp;

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Button from '../Button';
// import ReturnSummary from './ReturnSummary';
// import classes from './ReturnCart.module.css';

// const CartTemp = ({ cartMode }) => {
//     const navigate = useNavigate();
//     const role = useSelector((state) => state.role.role);
//     const deliveryBoyId = useSelector((state) =>
//         role === 'supervisor' ? state.supervisor.deliveryBoyId : state.deliveryBoy.id
//     );
//     const token = useSelector((state) => state.auth.token); // Assuming token is stored in auth slice
//     const [returnSummary, setReturnSummary] = useState(false);
//     const [cartData, setCartData] = useState({ orders: [], items: [] });
//     const [showShortageModal, setShowShortageModal] = useState(false);

//     // Fetch API data
//     useEffect(() => {
//         const fetchCartData = async () => {
//             try {
//                 const response = await fetch('http://localhost:5000/api/carts/get_return_summary_cart', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         Authorization: `Bearer ${token}`,
//                     },
//                     body: JSON.stringify({
//                         deliveryBoyId: deliveryBoyId,
//                     }),
//                 });
//                 const result = await response.json();
//                 if (result.success) {
//                     setCartData(result.data);
//                 } else {
//                     console.error('Error fetching return summary:', result.message);
//                 }
//             } catch (error) {
//                 console.error('API error:', error);
//             }
//         };

//         fetchCartData();
//     }, [deliveryBoyId, token]);

//     const handleBack = () => {
//         setReturnSummary(false);
//     };

//     const summaryHandler = () => {
//         setReturnSummary(true);
//     };

//     const handleSelect = (mode) => {
//         if (mode === 'back') navigate(-1);
//     };

//     return (
//         <div className={classes.returnsummarycontainer}>
//             <h1 className={classes.mainheading}>
//                 {cartMode === 'return' ? 'Return Value = 14,252' : 'Expiry Returns'}
//             </h1>
//             {cartMode === 'expiry' && <h2 className={classes.exptotal}>Total = 14,231</h2>}
//             <div className={classes.summaryheader}>
//                 <Button buttonClass={'back-button'} onSelect={() => handleSelect('back')}>
//                     Back
//                 </Button>
//                 {cartMode === 'return' && role === 'supervisor' && (
//                     <span className={classes.returnsummary} onClick={summaryHandler}>
//                         Return Summary
//                     </span>
//                 )}
//             </div>
//             {cartMode === 'expiry' && role === 'supervisor' ? (
//                 <div className={classes.expiryContainer}>
//                     <p className={classes.verifyText}>Verify that MRP of expiry item is 14,234</p>
//                     <button className={classes.verifyButton}>Yes Verified</button>
//                     <button className={classes.shortageButton} onClick={() => setShowShortageModal(true)}>
//                         No Report Shortage
//                     </button>

//                     {showShortageModal && (
//                         <div className={classes.shortageModal}>
//                             <form className={classes.shortageForm}>
//                                 <div className={classes.formGroup}>
//                                     <label>MRP Received:</label>
//                                     <input type="number" name="mrpReceived" className={classes.input} />
//                                 </div>
//                                 <div className={classes.formGroup}>
//                                     <label>Shortage Equals:</label>
//                                     <input type="number" name="shortage" className={classes.input} />
//                                 </div>
//                                 <button type="submit" className={classes.submitButton}>
//                                     Submit Shortage Report
//                                 </button>
//                             </form>
//                         </div>
//                     )}
//                 </div>
//             ) : (
//                 <div className={classes.summarycontainer}>
//                     <table className={classes.summarytable}>
//                         <thead>
//                             <tr>
//                                 <th>Bill No</th>
//                                 <th>Shop Name</th>
//                                 <th>Amount</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {cartData.orders.map((item, index) => (
//                                 <tr key={index}>
//                                     <td>{item.billNo}</td>
//                                     <td>{item.customerName}</td>
//                                     <td>{item.totalAmountOfOrder}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             )}
//             {role === 'supervisor' && cartMode === 'return' && (
//                 <button className={classes.nextButton} onClick={summaryHandler}>
//                     Next - Check Items
//                 </button>
//             )}
//             <ReturnSummary isOpen={returnSummary} onBack={handleBack} data={cartData} />
//         </div>
//     );
// };

// export default CartTemp;
