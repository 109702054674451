import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginForm.module.css';
import { LOGIN_ROUTE } from '../../enums/routes';

function LogInForm() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);  // Clear previous errors

        const authData = { userName, password };

        try {
            const response = await fetch(LOGIN_ROUTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(authData),
            });

            if (response.status === 401 || response.status === 404) {
                const resData = await response.json();
                return setError(resData.message || 'Authentication failed');
            }

            if (!response.ok) {
                throw new Error('Could not authenticate user');
            }

            const resData = await response.json();
            const token = resData.token;
            const role = resData.role;

            // Save token and role in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);

            if (role === 'supervisor') {
                navigate('/supervisordashboard', {
                    state: {
                        supervisorDetails: resData.supervisorDetails,
                        brands: resData.brands,
                        token: token,
                        role:role
                    },
                });
            } else if (role === 'deliveryBoy') {
                navigate('/', {
                    state: {
                        deliveryBoyDetails: resData.deliveryBoyDetails,
                        token: token,
                        role:role
                    },
                });
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <h1>Log in</h1>
            {error && <p className={styles.error}>{error}</p>}
            <p>
                <label htmlFor="email">UserName</label>
                <input
                    id="userName"
                    type="text"
                    name="userName"
                    placeholder='Enter Username'
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                />
            </p>
            <p>
                <label htmlFor="password">Password</label>
                <div className={styles.passwordWrapper}>
                    <input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={password}
                        placeholder='Enter Password'
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <span
                        className={styles.eyeIcon}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {password.length !== 0  ? '👁️' : ''} {/* Icon changes based on the visibility state */}
                    </span>
                </div>
            </p>
            <div className={styles.actions}>
                <button type="submit">
                    Log In
                </button>
            </div>
        </form>
    );
}

export default LogInForm;
