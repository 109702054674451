import React from 'react';
import styles from './AcceptBillModal.module.css';

const AcceptBillModal = ({ bill, closeModal }) => {
    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <button className={styles.backButton} onClick={closeModal}>
                    Back
                </button>
                <h2>Are you sure you want to accept this bill of the selected salesman?</h2>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Bill No.</th>
                            <th>Customer</th>
                            <th>Original Amount</th>
                            <th>Outstanding Amount</th>
                            <th>Bill Due</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{bill.billNo}</td>
                            <td>{bill.customer}</td>
                            <td>{bill.originalAmount}</td>
                            <td>{bill.outstandingAmount}</td>
                            <td>{bill.billDue}</td>
                        </tr>
                    </tbody>
                </table>
                <button className={styles.acceptButton} onClick={closeModal}>
                    Accept
                </button>
            </div>
        </div>
    );
};

export default AcceptBillModal;
