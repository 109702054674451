import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deliveryBoyDetails: { id: '', userName: '' },
  token: null,
  summary: {
    isShoplistAccepted: false,
    isItemlistAccepted: false,
  },
};

const deliveryBoySlice = createSlice({
  name: 'deliveryBoy',
  initialState,
  reducers: {
    setDeliveryBoyData: (state, action) => {
      state.deliveryBoyDetails = action.payload.deliveryBoyDetails;
      state.token = action.payload.token;
    },
    setShoplistAccepted: (state, action) => {
      state.summary.isShoplistAccepted = action.payload;
    },
    setItemlistAccepted: (state, action) => {
      state.summary.isItemlistAccepted = action.payload;
    },
    clearDeliveryBoyData: (state) => {
      state.deliveryBoyDetails = null;
      state.token = null;
      state.summary.isShoplistAccepted = false;
      state.summary.isItemlistAccepted = false;
    },
  },
});

export const { setDeliveryBoyData, setShoplistAccepted, setItemlistAccepted, clearDeliveryBoyData } = deliveryBoySlice.actions;

export default deliveryBoySlice.reducer;

