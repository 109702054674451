import classes from "./CashOnlineModal.module.css";
import { useState } from "react";
import { toast } from "react-toastify";
import GlobalLoader from "../../Assets/Loader/GlobalLoader";
import { useSelector } from "react-redux";
import { PAYMENTS } from "../../enums/routes";
import { useNavigate } from "react-router-dom";
function CashOnlineModal({ closeModal, paymentMode, data }) {
    const [isLoading, setIsLoading] = useState(false);
    const [confirmAmount, setConfirmAmount] = useState(false);
    const [isExpiryAmountReceived, setIsExpiryAmountReceived] = useState(false);
    const [billImage, setBillImage] = useState(null);
    const [chequeImage, setChequeImage] = useState(null);
    const [screenshot,setScreenshot] = useState(null);
    const [chequeDate, setChequeDate] = useState("");
    const [chequeNumber, setChequeNumber] = useState("");
    const navigate = useNavigate();
    const token = useSelector(state => state.deliveryBoy.token);

    const handleFileChange = (event, setter) => {
        const file = event.target.files[0];
        if (file) {
            setter(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        // Prepare the request body using FormData
        const formData = new FormData();
        formData.append("brandOrderId", data.item.brandOrderId);
        formData.append("paymentMethod", paymentMode);
        formData.append("confirmAmount", confirmAmount);
        formData.append("isExpiryAmountRecived", isExpiryAmountReceived);
    
        //Append files and other details based on the payment mode
        switch (paymentMode) {
            case "cash":
                if (billImage) formData.append("cashBillImage", billImage);  // Append the file
                break;
            case "online":
                if (billImage) formData.append("onlineBillImage", billImage);
                if (screenshot) formData.append("paymentScreenshot", screenshot);
                break;
            case "fullSignCredit":
                if (billImage) formData.append("fullSignCreditBillImage", billImage);
                break;
            case "fullCheque":
                if (billImage) formData.append("chequeBillImage", billImage);
                if (chequeImage) formData.append("chequeImage", chequeImage);
                formData.append("chequeDate", chequeDate);
                formData.append("chequeNumber", chequeNumber);
                break;
            default:
                break;
        }
        try {
            const response = await fetch(PAYMENTS, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });
    
            if (response.ok) {
                toast.success("Payment received");
                navigate('/');
                closeModal();
            } else {
                const responseData = await response.json();
                toast.error(responseData.message || "Payment failed");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
        {isLoading && <GlobalLoader />}
        <div className={classes.modalOverlay}>
            <div className={classes.modalContent}>
                <div className={classes.modalHeader}>
                    <button className={classes.modalCloseButton} onClick={closeModal}>
                        Back
                    </button>
                </div>
                <div className={classes.modalBody}>
                    <div className={classes.billDetail}>Bill Number: {data.item.billNo}</div>
                    <div className={classes.billDetail}>Shop Name: {data.item.customerName}</div>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <label className={classes.checkboxLabel}>
                            <input
                                type="checkbox"
                                className={classes.checkboxInput}
                                required
                                onChange={() => setConfirmAmount(!confirmAmount)}
                            />{" "}
                            Confirm Amount : {data.item.billAmount}
                        </label>
                        <label className={classes.checkboxLabel}>
                            <input
                                type="checkbox"
                                className={classes.checkboxInput}
                                required
                                onChange={() => setIsExpiryAmountReceived(!isExpiryAmountReceived)}
                            />{" "}
                            Confirm Expiry Amount : {data.item.expiryAmount}
                        </label>
                        <label className={classes.fileLabel}>
                            <div className={classes.fileButton}>
                                Upload Bill Pic
                                <input
                                    type="file"
                                    className={classes.fileInput}
                                    capture="environment" // 'user' for front camera, 'environment' for back camera
                                    required
                                    onChange={(e) => handleFileChange(e, setBillImage)}
                                />
                            </div>
                        </label>

                        {paymentMode === "fullCheque" && (
                            <>
                                <div className={classes.inputGroup}>
                                    <label className={classes.inputLabel}>
                                        Enter Cheque Number
                                    </label>
                                    <input
                                        type="number"
                                        className={classes.inputField}
                                        value={chequeNumber}
                                        required
                                        onChange={(e) => setChequeNumber(e.target.value)}
                                    />
                                </div>
                                <div className={classes.inputGroup}>
                                    <label className={classes.inputLabel}>
                                        Enter Cheque Date
                                    </label>
                                    <input
                                        type="date"
                                        className={classes.inputField}
                                        min={new Date().toISOString().split("T")[0]}
                                        value={chequeDate}
                                        required
                                        onChange={(e) => setChequeDate(e.target.value)}
                                    />
                                </div>
                            </>
                        )}

                        {(paymentMode === "online" || paymentMode === "fullCheque") && (
                            <label className={classes.fileLabel}>
                                <div className={classes.fileButton}>
                                    {paymentMode === "online"
                                        ? "Upload Screenshot"
                                        : "Upload Cheque Photo"}
                                </div>
                                <input
                                    type="file"
                                    className={classes.fileInput}
                                    capture='environment'
                                    required
                                    onChange={(e) =>
                                        paymentMode === "online"
                                            ? handleFileChange(e, setScreenshot)
                                            : handleFileChange(e, setChequeImage)
                                    }
                                />
                            </label>
                        )}
                        <button type="submit" className={classes.submitButton}>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </>
    );
}
export default CashOnlineModal;