import React, { useState } from 'react';
import styles from './SummaryAllocation.module.css';

const SummaryAllocationModal = ({ summary, deliveryBoys, onAllocate, onDispatch, onClose }) => {
  const [selectedBoy, setSelectedBoy] = useState(summary.deliveryBoy || '');

  // Filter out the current delivery boy for reallocation
  // const availableDeliveryBoys = deliveryBoys.filter(
  //   boy => boy._id !== (summary.deliveryBoyName && summary.deliveryBoyId)
  // );

  return (
    <div className={styles.backdrop} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        {summary.isAllocatedBySupervisor ? (
          <>
            <h2 className={styles.heading}>{summary.summaryName} is allocated to {summary.deliveryBoyName || 'No One'}</h2>
            {/* <select
              className={styles.dropdown}
              value={selectedBoy}
              onChange={(e) => setSelectedBoy(e.target.value)}
            >
              <option value="" disabled>Select new delivery boy</option>
              {availableDeliveryBoys.map((boy) => (
                <option key={boy._id} value={boy._id}>{boy.userName}</option>
              ))}
            </select> */}
            {/* <button className={styles.allocateButton} onClick={() => onAllocate(summary.uuid, selectedBoy)}>
              Reallocate
            </button> */}
           {!summary.isDispatchedBySupervisor && <button className={styles.allocateButton} onClick={() => onDispatch(summary.uuid)}>
              Dispatch
            </button>} 
          </>
        ) : (
          <>
            <h2 className={styles.heading}>Allocate {summary.summaryName}</h2>
            <select
              className={styles.dropdown}
              value={selectedBoy}
              onChange={(e) => setSelectedBoy(e.target.value)}
            >
              <option value="" disabled>Select delivery boy</option>
              {deliveryBoys.map((boy) => (
                <option key={boy._id} value={boy._id}>{boy.userName}</option>
              ))}
            </select>
            <button className={styles.allocateButton} onClick={() => onAllocate(summary.uuid, selectedBoy)}>
              Allocate
            </button>
          </>
        )}
        <button className={styles.allocateButton} onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SummaryAllocationModal;


