import React, { useState } from 'react';
import styles from './BillClearence.module.css'
import CreditStatusBox from './CreditStatusBox';
import AcceptBillModal from '../../../Components/Modal/AcceptBillModal';

const ClearBills = () => {
    const [selectedBill, setSelectedBill] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const billsData = [
        { billNo: '001', customer: 'John Doe', originalAmount: '5000', outstandingAmount: '1000', billDue: '2024-08-15' },
        { billNo: '002', customer: 'Jane Doe', originalAmount: '7000', outstandingAmount: '2000', billDue: '2024-08-16' },
        // ... other bills
    ];

    const handleAcceptClick = (bill) => {
        setSelectedBill(bill);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedBill(null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.billIssuance}>
                <h1 className={styles.heading}>Bill Clearance</h1>
                <div className={styles.billIssuetop}>
                    <p className={styles.billIssueText}>Clear Bills Of</p>
                    <div className={styles.salesmanSelection}>
                        <select className={styles.dropdown}>
                            <option value="salesman 1">salesman 1</option>
                            <option value="salesman 2">salesman 2</option>
                            <option value="salesman 3">salesman 3</option>
                            <option value="salesman 4">salesman 4</option>
                        </select>
                        <button className={styles.showButton}>Show</button>
                    </div>
                </div>
            </div>

            <CreditStatusBox />

            <h3 className={styles.nonIssuedText}>Issued Bills</h3>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Bill No.</th>
                        <th>Customer</th>
                        <th>Original Amount</th>
                        <th>Outstanding Amount</th>
                        <th>Bill Due</th>
                        <th>Request</th>
                    </tr>
                </thead>
                <tbody>
                    {billsData.map((bill) => (
                        <tr key={bill.billNo}>
                            <td>{bill.billNo}</td>
                            <td>{bill.customer}</td>
                            <td>{bill.originalAmount}</td>
                            <td>{bill.outstandingAmount}</td>
                            <td>{bill.billDue}</td>
                            <td>
                                <button 
                                    className={styles.acceptButton} 
                                    onClick={() => handleAcceptClick(bill)}
                                >
                                    Accept
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <AcceptBillModal
                    bill={selectedBill} 
                    closeModal={closeModal}
                />
            )}
        </div>
    );
};

export default ClearBills;
