export const LOGIN_ROUTE = 'https://alizretailbackend.in/api/login';
export const ORDER_UPLOAD = 'https://alizretailbackend.in/api/upload/order_upload';
export const ASSIGN_SUMMARY_TO_DELIVERY_BOYS = 'https://alizretailbackend.in/api/summary/assign_summary_to_delivery_boy?uuid=';
export const GET_SUMMARIES_AND_DELIVERY_BOYS = 'https://alizretailbackend.in/api/summary/get_summaries_and_delivery_boys?brandId=';
export const GET_ASSIGNED_AND_DISPATCHED_ORDERS = 'https://alizretailbackend.in/api/delivery_boy/get_assigned_and_disptached_orders';
export const ACCEPT_SUMMARY = 'https://alizretailbackend.in/api/delivery_boy/accept_order_by_delivery_boy';
export const PAYMENTS = 'https://alizretailbackend.in/api/upload/payment';
export const GET_MONEY_CART='https://alizretailbackend.in/api/carts/get_money_cart';
export const CLEAR_MONEY_CART ='https://alizretailbackend.in/api/supervisor/clear_money_cart';
export const DISPATCH_SUMMARY ='https://alizretailbackend.in/api/summary/dispatch_assigned_summary_to_delivery_boy';
export const RETURN_CART = 'https://alizretailbackend.in/api/carts/get_return_summary_cart';
export const EXPIRY_CART = 'https://alizretailbackend.in/api/carts/get_expiry_summary_cart';
export const GET_DISPATCHED_SUMMARIES = 'https://alizretailbackend.in/api/summary/get_dispatched_summaries';

// export const LOGIN_ROUTE = 'http://localhost:5000/api/login';
// export const ORDER_UPLOAD = 'http://localhost:5000/api/upload/order_upload';
// export const ASSIGN_SUMMARY_TO_DELIVERY_BOYS = 'http://localhost:5000/api/summary/assign_summary_to_delivery_boy?uuid=';
// export const GET_SUMMARIES_AND_DELIVERY_BOYS = 'http://localhost:5000/api/summary/get_summaries_and_delivery_boys?brandId=';
// export const GET_ASSIGNED_AND_DISPATCHED_ORDERS = 'http://localhost:5000/api/delivery_boy/get_assigned_and_disptached_orders';
// export const ACCEPT_SUMMARY = 'http://localhost:5000/api/delivery_boy/accept_order_by_delivery_boy';
// export const PAYMENTS = 'http://localhost:5000/api/upload/payment';
// export const GET_MONEY_CART='http://localhost:5000/api/carts/get_money_cart';
// export const CLEAR_MONEY_CART ='http://localhost:5000/api/supervisor/clear_money_cart';
// export const DISPATCH_SUMMARY ='http://localhost:5000/api/summary/dispatch_assigned_summary_to_delivery_boy';
// export const RETURN_CART = 'http://localhost:5000/api/carts/get_return_summary_cart';
// export const EXPIRY_CART = 'http://localhost:5000/api/carts/get_expiry_summary_cart';
// export const GET_DISPATCHED_SUMMARIES = 'http://localhost:5000/api/summary/get_dispatched_summaries';

// export const LOGIN_ROUTE = 'http://192.168.29.150:5000/api/login';
// export const ORDER_UPLOAD = 'http://192.168.29.150:5000/api/upload/order_upload';
// export const ASSIGN_SUMMARY_TO_DELIVERY_BOYS = 'http://192.168.29.150:5000/api/summary/assign_summary_to_delivery_boy?uuid=';
// export const GET_SUMMARIES_AND_DELIVERY_BOYS = 'http://192.168.29.150:5000/api/summary/get_summaries_and_delivery_boys?brandId=';
// export const GET_ASSIGNED_AND_DISPATCHED_ORDERS = 'http://192.168.29.150:5000/api/delivery_boy/get_assigned_and_disptached_orders';
// export const ACCEPT_SUMMARY = 'http://192.168.29.150:5000/api/delivery_boy/accept_order_by_delivery_boy';
// export const PAYMENTS = 'http://192.168.29.150:5000/api/upload/payment';
// export const GET_MONEY_CART='http://192.168.29.150:5000/api/carts/get_money_cart';
// export const CLEAR_MONEY_CART ='http://192.168.29.150:5000/api/supervisor/clear_money_cart';
// export const DISPATCH_SUMMARY ='http://192.168.29.150:5000/api/summary/dispatch_assigned_summary_to_delivery_boy';
// export const RETURN_CART = 'http://192.168.29.150:5000/api/carts/get_return_summary_cart';
// export const EXPIRY_CART = 'http://192.168.29.150:5000/api/carts/get_expiry_summary_cart';
// export const GET_DISPATCHED_SUMMARIES = 'http://192.168.29.150:5000/api/summary/get_dispatched_summaries';
