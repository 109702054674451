import React, { useState } from 'react';
import classes from './Tabs.module.css';
import { useNavigate } from 'react-router-dom';

const TabComponent = () => {
    const [activeTab, setActiveTab] = useState('');
    const navigate = useNavigate();

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`/${tab}`);
    };

    return (
        <div className={classes.tabscontainer}>
            <div
                className={`${classes.tab} ${activeTab === 'money' ? 'active' : ''}`}
                onClick={() => handleTabClick('money')}
            >
                Money
            </div>
            <div
                className={`${classes.tab} ${activeTab === 'return' ? 'active' : ''}`}
                onClick={() => handleTabClick('return')}
            >
                Return
            </div>
            <div
                className={`${classes.tab} ${activeTab === 'expiry' ? 'active' : ''}`}
                onClick={() => handleTabClick('expiry')}
            >
                Expiry
            </div>
        </div>
    );
};

export default TabComponent;
