import React, { useState } from 'react';
import classes from './ShopListTab.module.css';
import CurrentStatus from '../../Pages/Supervisor/CurrentStatus/CurrentStatus';
import SummaryTemp from '../../Pages/Supervisor/Summary/SummaryTemp';
import { useSelector, useDispatch } from 'react-redux';
import { setSummaries } from '../../store/supervisor-slice';
import { setDeliveryBoys } from '../../store/supervisor-slice';
import { GET_SUMMARIES_AND_DELIVERY_BOYS } from '../../enums/routes';
import Summary from '../../Pages/Supervisor/Summary/Summary';
import CreditBills from '../../Pages/Supervisor/Credit/CreditBills';
import ReportPage from '../../Pages/Supervisor/Report/ReportPage';
import GlobalLoader from '../../Assets/Loader/GlobalLoader';

const SupervisorDashboardTab = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('currentstatus');
    const [loading, setLoading] = useState(false);
    const [isSummaryEmpty,setIsSummaryEmpty] = useState(false);
    const token = useSelector((state) => state.supervisor.token)
    const brandId = useSelector((state) => state.supervisor.selectedBrand.id)

    const handleTabClick = async (tab) => {
        setActiveTab(tab);
        if (tab === 'summary') {
            setLoading(true);
            try {
                const response = await fetch(`${GET_SUMMARIES_AND_DELIVERY_BOYS}${brandId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                const data = await response.json();
                if (response.status === 201){
                    return setIsSummaryEmpty(true)
                }else if (data.success) {
                    setIsSummaryEmpty(false);
                    dispatch(setSummaries(data.data.summaries));
                    dispatch(setDeliveryBoys(data.data.deliveryBoys));
                } else {
                    console.error('Failed to fetch summaries and delivery boys:', data.message);
                }
            } catch (error) {
                console.error('Error fetching summaries and delivery boys:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            {loading ? <GlobalLoader /> : <div className={classes.tabContainer}>
                <div className={classes.tabHeader}>
                    <div
                        className={`${classes.tab} ${activeTab === 'currentstatus' ? classes.active : ''}`}
                        onClick={() => handleTabClick('currentstatus')}
                    >
                        Current Status
                    </div>
                    <div
                        className={`${classes.tab} ${activeTab === 'summary' ? classes.active : ''}`}
                        onClick={() => handleTabClick('summary')}
                    >
                        Summary
                    </div>
                    {/* <div
                    className={`${classes.tab} ${activeTab === 'credit' ? classes.active : ''}`}
                    onClick={() => handleTabClick('credit')}
                >
                    Credit
                </div>
                <div
                    className={`${classes.tab} ${activeTab === 'report' ? classes.active : ''}`}
                    onClick={() => handleTabClick('report')}
                >
                    Report
                </div> */}
                </div>
                <div className={classes.tabContent}>
                    {activeTab === 'currentstatus' &&
                        <>
                            <CurrentStatus />
                        </>
                    }
                    {activeTab === 'summary' &&
                        <>
                            <SummaryTemp isSummaryEmpty={isSummaryEmpty}/>
                        </>
                    }
                    {/* {activeTab === 'credit' &&
                    <>
                        <CreditBills />
                    </>
                }
                {activeTab === 'report' &&
                    <>
                        <ReportPage type={'report'} />
                    </>
                } */}
                </div>
            </div>}
        </>
    );
};

export default SupervisorDashboardTab;
