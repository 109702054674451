// import React, { forwardRef } from 'react';
// import styles from './ConfirmCashModal.module.css';

// const ConfirmCashModal = forwardRef((props, ref) => {
//   const closeModal = () => {
//     ref.current.close();
//   };

//   return (
//     <dialog ref={ref} className={styles.cashmodal}>
//       <button className={styles.backButton} onClick={closeModal}>Back</button>
//       <h3 className={styles.cashmodalh2}>{`Confirm that you are receiving amount ${props.totalAmount} in full cash`}</h3>
//       <button className={styles.confirmButton}>Confirm</button>
//     </dialog>
//   );
// });

// export default ConfirmCashModal;

import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './ConfirmCashModal.module.css';
import GlobalLoader from '../../Assets/Loader/GlobalLoader';
import { CLEAR_MONEY_CART } from '../../enums/routes';
import { useSelector } from 'react-redux';

const ConfirmCashModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const selectedSummary = useSelector((state) => state.supervisor.selectedSummary);
  const navigate = useNavigate();

  const closeModal = () => {
    ref.current.close();
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const response = await fetch(CLEAR_MONEY_CART, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deliveryBoyId: selectedSummary.deliveryBoyId,
          clearCash: true,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (response.ok) {
        closeModal();
        window.location.reload();
        navigate('/money');
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('Error clearing money cart:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <dialog ref={ref} className={styles.cashmodal}>
      {loading && <GlobalLoader />}
      <button className={styles.backButton} onClick={closeModal} disabled={loading}>
        Back
      </button>
      <h3 className={styles.cashmodalh2}>
        {`Confirm that you are receiving amount ${props.totalAmount} in full cash`}
      </h3>
      <button className={styles.confirmButton} onClick={handleConfirm} disabled={loading}>
        Confirm
      </button>
    </dialog>
  );
});

export default ConfirmCashModal;

