import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    supervisorDetails: null,
    brands: [],
    token: null,
    selectedBrand: { id: '', name: '', summaries: [] },
    deliveryBoys: [],
    selectedSummary: null
};

const supervisorSlice = createSlice({
    name: 'supervisor',
    initialState,
    reducers: {
        setSupervisorData: (state, action) => {
            state.supervisorDetails = action.payload.supervisorDetails;
            state.brands = action.payload.brands;
            state.token = action.payload.token;
        },
        setSelectedBrand(state, action) {
            state.selectedBrand = {
                id: action.payload.id,
                name: action.payload.name,
                summaries: action.payload.summaries || [],  // Set summaries during selection
            };
        },
        setSummaries(state, action) {
            // Find the selected brand and update its summaries
            const brandIndex = state.brands.findIndex(brand => brand.id === state.selectedBrand.id);
            if (brandIndex !== -1) {
                state.brands[brandIndex].summaries = action.payload;  // Update summaries in the brand
                state.selectedBrand.summaries = action.payload;  // Update selected brand summaries
            }
        },
        setDeliveryBoys(state, action) {
            state.deliveryBoys = action.payload;
        },
        setSelectedSummary(state, action) {
            state.selectedSummary = action.payload;
        },
        clearSupervisorData: (state) => {
            state.supervisorDetails = null;
            state.brands = [];
            state.token = null;
            state.selectedBrand = { id: '', name: '', summaries: [] };  // Reset summaries
            state.summaries = [];
            state.deliveryBoys = [];
        },
    },
});

export const { setSupervisorData, clearSupervisorData, setSummaries, setDeliveryBoys, setSelectedBrand ,setSelectedSummary} = supervisorSlice.actions;

export default supervisorSlice.reducer;
