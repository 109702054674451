import { GET_ASSIGNED_AND_DISPATCHED_ORDERS, GET_MONEY_CART } from '../enums/routes';
import { ACCEPT_SUMMARY } from '../enums/routes';

export const fetchShopData = async (token) => {
    const response = await fetch(GET_ASSIGNED_AND_DISPATCHED_ORDERS, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const result = await response.json();
    if (!response.ok) {
        throw new Error(result.message || 'Failed to fetch data');
    }
    return result.data;
};

export const handleAccept = async ({ uuid, token }) => {
    const apiPayload = {
        isAcceptedByDeliveryBoy: true,
        uuid: uuid,
    };

    const response = await fetch(ACCEPT_SUMMARY, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(apiPayload),
    });

    const result = await response.json();

    if (!response.ok) {
        throw new Error(result.message || 'Something went wrong');
    }

    return result.message || 'Summary Accepted Successfully';
};

export const fetchMoneyCartData = async (role, token, selectedSummary) => {
    let response;
  
    if (role === 'supervisor') {
      response = await fetch(`${GET_MONEY_CART}?deliveryBoyId=${selectedSummary.deliveryBoyId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } else {
      response = await fetch(GET_MONEY_CART, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }
    const result = await response.json();
  
    if (!response.ok) {
        throw new Error(result.message || 'Failed to fetch data');
    }
    return result.data;
  };