import React from 'react';
import Cart from '../../Components/ReturnCart/Cart';
import CartTemp from '../../Components/ReturnCart/CartTemp';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/Button';

const ReturnCart = () => {
  const navigate = useNavigate();
  function handleSelect(mode) {
    return mode === 'back' ? navigate(-1) : null;
  }
  return (
    <>
      <Button buttonClass={'back-button'} onSelect={() => handleSelect('back')}>Back</Button>
      {/* <Cart cartMode={'return'} isSupervisor={false} /> */}
      <CartTemp cartMode={'return'} />
    </>
  );
};

export default ReturnCart;
