import React, { useState } from 'react';
import styles from './LogInPage.module.css';
import LogInForm from './LoginForm';

const LogInPage = () => {

  return (
    <div className={styles.container}>
          <LogInForm />
      </div>
  );
};

export default LogInPage;

