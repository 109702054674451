import React from 'react';
import classes from "../Assets/CSS/ShopList.module.css";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ShopList = ({ type, data }) => {
    const navigate = useNavigate();
    const role = useSelector((state) => state.role.role);
    
    if (data.length === 0) {
        return <h3 className={classes.error}>No Data Found!</h3>;
    }

    return (
        <div className={classes.tablecontainer}>
            <div className={classes.shoplist}>
                <table>
                    <tbody>
                        {type === 'shop' ? data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.billNo}</td>
                                <td>{item.customerName}</td>
                                <td className={classes.headeramount}>{item.billAmount}</td>
                                <td>
                                    {role === 'deliveryBoy' && (
                                        <button
                                        className={`${classes.statusbutton} ${classes[item.paymentStatus ? item.paymentStatus.toLowerCase() : 'not-dispatched']} ${item.paymentStatus === 'paid' ? classes.nonClickable : ''}`}
                                            onClick={() => {
                                                if (item.deliveryStatus) {
                                                    navigate('/billcombination', {
                                                        state: { item: item },
                                                    });
                                                }
                                            }}
                                        >
                                            {item.paymentStatus ? item.paymentStatus : 'Not Dispatched'}
                                        </button>
                                    )}
                                    {role === 'supervisor' && (
                                        <button className={`${classes.deliveryStatus} ${classes[item.deliveryStatus]}`}>
                                            {item.deliveryStatus || 'Pending'}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        )) : data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.itemName}</td>
                                <td>{item.itemQuantity}</td>
                                <td className={classes.headeramount}>{item.amountOfItems}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ShopList;


