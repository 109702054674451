import React from 'react';
import ReactModal from 'react-modal';
import styles from './ConfirmBillIssueModal.module.css';

const ConfirmBillIssueModal = ({ isOpen, onClose, selectedSalesman, selectedBills, onConfirm }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
            ariaHideApp={false}
        >
            <button onClick={onClose} className={styles.backButton}>Back</button>
            <h2>Are you sure you want to issue these bills to {selectedSalesman}?</h2>
            <table className={styles.modalTable}>
                <thead>
                    <tr>
                        <th>Bill No.</th>
                        <th>Customer</th>
                        <th>Original Amount</th>
                        <th>Outstanding Amount</th>
                        <th>Bill Due</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedBills.map((bill) => (
                        <tr key={bill.billNo}>
                            <td>{bill.billNo}</td>
                            <td>{bill.customer}</td>
                            <td>{bill.originalAmount}</td>
                            <td>{bill.outstandingAmount}</td>
                            <td>{bill.billDue}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={onConfirm} className={styles.confirmButton}>Confirm</button>
        </ReactModal>
    );
};

export default ConfirmBillIssueModal;
 