import { redirect } from "react-router-dom";
  export function checkAuthLoaderDelivery() {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
  
      if (!token && !role) {
        return redirect('/login');
      }else if(token && role !== 'deliveryBoy'){
        localStorage.clear();
        return redirect ('/login')
      }

      return null;
    }

    export function checkAuthLoaderSupervisor() {
      const token = localStorage.getItem('token');
      const role = localStorage.getItem('role');
    
        if (!token && !role) {
          return redirect('/login');
        }else if(token && role !== 'supervisor'){
          localStorage.clear();
          return redirect ('/login')
        }
        
        return null;
      }

      export function checkAuthLoaderCommon() {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
      
          if (!token && !role) {
            return redirect('/login');
          }else if(token && (role !== 'deliveryBoy' && role !== 'supervisor')){
            localStorage.clear();
            return redirect ('/login')
          }
    
          return null;
        }

