import React, { useState } from "react";
import ButtonCancel from "../CancelButton/ButtonCancel";
import classes from "./CancelModal.module.css";
import OrderReturnModal from "../ReturnOrderModal/OrderReturnModal";
import { CANCEL_BUTTON } from "../../enums/constants";

const CancelModal = ({ onClose, data }) => {
  const [isOrderReturnModalOpen, setIsOrderReturnModalOpen] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("");
  const openOrderReturnModal = (label) => {
    setIsOrderReturnModalOpen(true);
    setButtonLabel(label);
  };
  const closeOrderReturnModal = () => setIsOrderReturnModalOpen(false);

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <button className={classes.backButton} onClick={onClose}>
          Back
        </button>
        <div className={classes.buttonContainer}>
          <ButtonCancel
            label={CANCEL_BUTTON.SHOPKEEPER_DENIED}
            onClick={() =>
              openOrderReturnModal(CANCEL_BUTTON.SHOPKEEPER_DENIED)
            }
          />
          <ButtonCancel
            label={CANCEL_BUTTON.SHOP_NOT_ATTEMPTED}
            onClick={() =>
              openOrderReturnModal(CANCEL_BUTTON.SHOP_NOT_ATTEMPTED)
            }
          />
        </div>
      </div>
      {isOrderReturnModalOpen && (
        <OrderReturnModal
          buttonHeading={buttonLabel}
          onClose={closeOrderReturnModal}
          orderDetails={data}
        />
      )}
    </div>
  );
};

export default CancelModal;
