import React, { useState } from 'react';
import styles from './IssueBills.module.css';
import CreditStatusBox from './CreditStatusBox';
import ConfirmBillIssueModal from '../../../Components/Modal/ConfirmBillIssueModal';

const IssueBills = () => {
    const [selectedSalesman, setSelectedSalesman] = useState('');
    const [selectedBills, setSelectedBills] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSalesmanChange = (e) => {
        setSelectedSalesman(e.target.value);
    };

    const handleBillSelection = (billData) => {
        setSelectedBills((prevSelectedBills) =>
            prevSelectedBills.some((bill) => bill.billNo === billData.billNo)
                ? prevSelectedBills.filter((bill) => bill.billNo !== billData.billNo)
                : [...prevSelectedBills, billData]
        );
    };

    const handleIssueSelectedBills = () => {
        if (selectedSalesman && selectedBills.length > 0) {
            setIsModalOpen(true);
        } else {
            alert('Please select a salesman and at least one bill to issue.');
        }
        console.log('Selected Bills:', selectedBills);
    };

    const handleConfirmIssue = () => {
        console.log('Confirmed Bills:', selectedBills);
        setIsModalOpen(false);
    };

    const billsData = [
        { billNo: '001', customer: 'John Doe', originalAmount: '5000', outstandingAmount: '1000', billDue: '2024-08-15' },
        { billNo: '002', customer: 'Jane Doe', originalAmount: '7000', outstandingAmount: '2000', billDue: '2024-08-16' },
        // Add more rows as needed
    ];

    return (
        <div className={styles.container}>
            <div className={styles.billIssuance}>
                <h1 className={styles.heading}>
                    Bill Issuance
                </h1>
                <div className={styles.billIssuetop}>
                    <p className={styles.billIssueText}>
                        Issue Bills to
                    </p>
                    <div className={styles.salesmanSelection}>
                        <select
                            value={selectedSalesman}
                            onChange={handleSalesmanChange}
                            className={styles.dropdown}>
                            {/* <option value="all">All</option> */}
                            <option value="salesman 1">salesman 1</option>
                            <option value="salesman 2">salesman 2</option>
                            <option value="salesman 3">salesman 3</option>
                            <option value="salesman 4">salesman 4</option>
                        </select>
                        <button className={styles.showButton}>Show</button>
                    </div>
                </div>
            </div>

            <CreditStatusBox />

            <p className={styles.freshBillText}>
                <i> Want to issue fresh bills? Select From</i>
            </p>
            <h3 className={styles.nonIssuedText}>Non Issued Bills</h3>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Bill No.</th>
                        <th>Customer</th>
                        <th>Original Amount</th>
                        <th>Outstanding Amount</th>
                        <th>Bill Due</th>
                    </tr>
                </thead>
                <tbody>
                    {billsData.map((bill) => (
                        <tr
                            key={bill.billNo}
                            className={selectedBills.some((selectedBill) => selectedBill.billNo === bill.billNo) ? styles.selectedRow : ''}
                            onClick={() => handleBillSelection(bill)}
                        >
                            <td>{bill.billNo}</td>
                            <td>{bill.customer}</td>
                            <td>{bill.originalAmount}</td>
                            <td>{bill.outstandingAmount}</td>
                            <td>{bill.billDue}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <button
                className={styles.issueButton}
                onClick={handleIssueSelectedBills}
            >
                Issue All Selected Bills
            </button>
            <ConfirmBillIssueModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                selectedSalesman={selectedSalesman}
                selectedBills={selectedBills}
                onConfirm={handleConfirmIssue}
            />
        </div>
    );
};

export default IssueBills;
