import Header from "../../Components/Header/Header";
import KPI from "../../Components/KPI/KPI";
import Tasks from "../../Components/Tasks";
import TabComponent from "../../Components/Tabs/TabComponent";
import ShopListTab from "../../Components/Tabs/ShopListTab";
import { useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { useEffect } from "react";
import { setDeliveryBoyData } from "../../store/deliveryExecutive-slice";
import GlobalLoader from "../../Assets/Loader/GlobalLoader";
import { setRole } from "../../store/role-slice";
function DeliveryBoyDashboard() {
    const location = useLocation();
    const dispatch = useDispatch();
    const deliveryExecutiveDetails = useSelector((state) => state.deliveryBoy.deliveryBoyDetails);

    useEffect(() => {
        if (location.state) {
          const { deliveryBoyDetails, token,role } = location.state;
          dispatch(setDeliveryBoyData({ deliveryBoyDetails, token }));
          dispatch(setRole(role));
        }
      }, [location.state, dispatch]);

      if(!deliveryExecutiveDetails){
        return <GlobalLoader />
      }
    return (
        <div className="Dashboard">
            {/* <KPI /> */}
            <Tasks />
            <TabComponent />
            <ShopListTab />
        </div>)
}
export default DeliveryBoyDashboard;