import classes from './KPI.module.css';
function KPI() {
    return (
        <div className={classes.performanceindicator}>
            <h2>Performance Indicator</h2>
            <div className={classes.indicatorbar}>
                <div className={classes.indicatorprogress} />
            </div>
        </div>
    );
}
export default KPI;