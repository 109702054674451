import { Outlet } from "react-router-dom";
import Header from "../../Components/Header/Header";

function RootLayoutSupervisor(){
    return(
    <>
    <Header />
    <Outlet />
    </>
    )
}
export default RootLayoutSupervisor;