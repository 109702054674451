import { React, useRef,useState } from "react";
import classes from "./Modal.module.css";
import cameraIcon from "../../Assets/camera-icon.png";
import Button from "../Button";
import ConfirmCashModal from "./ConfirmCashModal";
import PartCashModal from "./PartCashModal";
import { useSelector } from "react-redux";
import { CLEAR_MONEY_CART } from "../../enums/routes";
import GlobalLoader from "../../Assets/Loader/GlobalLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Modal = ({ isOpen, onClose, paymentMode, orderDetails, role}) => {

  const selectedSummary = useSelector((state) => state.supervisor.selectedSummary);
  const ConfirmCashModalRef = useRef(null);
  const PartCashModalRef = useRef(null);
  const [loading,setLoading] = useState(false);
  const token = useSelector((state)=>state.supervisor.token)
  const navigate = useNavigate();

  const openConfirmCashModal = () => {
    ConfirmCashModalRef.current.showModal();
  };

  const openPartCashModal = () => {
    PartCashModalRef.current.showModal();
  };
  const mode = paymentMode.charAt(0).toUpperCase() + paymentMode.slice(1).toLowerCase();

  const paymentDetails = {
    cash: orderDetails.cashOrderDetails,
    online: orderDetails.onlineOrderDetails,
    credit: orderDetails.creditOrderDetails,
    cheque: orderDetails.chequeOrderDetails,
  }[paymentMode] || [];

  const handleConfirmation = async () => {
    const deliveryBoyId = selectedSummary.deliveryBoyId;
    setLoading(true);

    let body = {
      deliveryBoyId: deliveryBoyId,
    };

    if (mode === "Credit") {
      body.clearCredit = true;
    } else if (mode === "Cheque") {
      body.clearCheque = true;
    } else if (mode === "Online") {
      body.clearOnline = true;
    }

    try {
      const response = await fetch(CLEAR_MONEY_CART, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      toast.success("Successfully cleared the payment!");
      onClose();
      navigate("/money");
    } catch (error) {
      toast.error("Failed to clear payment. Please try again.");
      console.error("Error clearing payment:", error);
    } finally {
      setLoading(false);
    }
  };


  if (!isOpen) return null;

  return (
    <div className={classes.modaloverlay}>
      <div className={classes.modalcontent}>
        <Button buttonClass={"round-cross-button"} onSelect={onClose}>X</Button>
        <h2>{mode} {mode === "Credit" ? "Bills" : "Payments"}</h2>
        {loading && <GlobalLoader />}
        <table>
          <thead>
            <tr>
              <th>Bill No.</th>
              <th>Shop Name</th>
              <th>Amount</th>
              {role === 'supervisor' && mode === "Cheque" && <th>Cheque No</th>}
              {(mode === "Cash" || mode === "Credit") ? null : <th>Image</th>}
            </tr>
          </thead>
          <tbody>
            {paymentDetails.map((item, index) => (
              <tr key={index}>
                <td>{item.billNo}</td>
                <td>{item.customerName}</td>
                <td>{item.totalAmountOfOrder}</td>
                {role === 'supervisor' && mode === "Cheque" && <td>{item.chequeNo}</td>}
                {(mode === "Cash" || mode === "Credit") ? null : (
                  <td>
                    <img className={classes.cameraicon} alt="camera-icon" src={item.signedBillImage || cameraIcon} />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {
          role === 'supervisor' && mode === "Cash" && (
            <div className={classes.supervisorSection}>
              <h3>Total Cash to Receive</h3>
              <div className={classes.amountBox}>{orderDetails.totalCashAmount}</div>
              <div className={classes.actionButtons}>
                <div
                  className={classes.receiveFullCash}
                  onClick={openConfirmCashModal}
                >
                  Receive Full Cash
                </div>
                <ConfirmCashModal ref={ConfirmCashModalRef} totalAmount={orderDetails.totalCashAmount} />
                {/* <div
                  className={classes.receivePartialCash}
                  onClick={openPartCashModal}
                >
                  Receive Cash in Part
                </div>
                <PartCashModal ref={PartCashModalRef} /> */}
              </div>
            </div>
          )}
        {
          role === 'supervisor' &&
          (mode === 'Online' || mode === 'Cheque' || mode === 'Credit') && (
            <button className={classes.closebutton} onClick={handleConfirmation}>
              {
                mode === "Online"
                  ? "Yes I have Checked the Screenshots"
                  : mode === "Cheque"
                    ? "Yes I have checked the cheques"
                    : "Yes I have checked the bills"
              }
            </button>
          )}
        <button className={classes.closebutton} onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
