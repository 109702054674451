import React, { useState } from 'react';
import styles from './Summary.module.css';
import SummaryAllocationModal from './SummaryAllocationModal';
import { useSelector } from 'react-redux';
import { ASSIGN_SUMMARY_TO_DELIVERY_BOYS, DISPATCH_SUMMARY } from '../../../enums/routes';
import GlobalLoader from '../../../Assets/Loader/GlobalLoader';
import { ToastContainer,toast } from 'react-toastify';

const SummaryTemp = ({ isSummaryEmpty }) => {
  //const summaries = useSelector((state) => state.supervisor.summaries);
  const summaries = useSelector((state) => state.supervisor.selectedBrand.summaries);
  const deliveryBoys = useSelector((state) => state.supervisor.deliveryBoys);
  const token = useSelector((state) => state.supervisor.token)

  const [selectedSummary, setSelectedSummary] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to determine the status of each summary based on supervisor allocation and dispatch status
  const getSummaryStatus = (summary) => {
    if (!summary.isAllocatedBySupervisor) return 'Not Allocated';
    if (summary.isAllocatedBySupervisor && !summary.isDispatchedBySupervisor) return 'Allocated';
    if (summary.isAllocatedBySupervisor && summary.isDispatchedBySupervisor) return 'Dispatched';
  };

  // API call for allocation using fetch
  const handleAllocate = async (summaryUuid, deliveryBoyId) => {
    setLoading(true);
    try {
      const response = await fetch(`${ASSIGN_SUMMARY_TO_DELIVERY_BOYS}${summaryUuid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ deliveryBoyId }),
      });
      console.log(response.message)
      if(response.ok){
        toast.success()
        toast.success(response.message)
      }else if (!response.ok) {
        toast.error('Failed to allocate');
      }

      setSelectedSummary(null); // Close modal after allocation
    } catch (error) {
      toast.error('Allocation failed:', error);
    } finally {
      setLoading(false);
    }
  };

  // API call for dispatch using fetch
  const handleDispatch = async (summaryId) => {
    setLoading(true);
    try {
      const response = await fetch(`${DISPATCH_SUMMARY}?uuid=${summaryId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to dispatch');
      }

      setSelectedSummary(null); // Close modal after dispatch
    } catch (error) {
      toast.error('Failed to Dispatch!',error);
      console.error('Dispatch failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <ToastContainer />
      {loading ? <GlobalLoader /> : <>{
        isSummaryEmpty ? <div>
          <p>Please Upload Summaries</p>
        </div> :
          <div className={styles.modalContainer}>
            <div className={styles.reportPage}>
              <h1 className={styles.heading}><u>Summary</u></h1>
            </div>
            <div className={styles.topSection}>
              {/* <div className={styles.unsummarizedInfo}>
                <div className={styles.infoBox}>
                  <p>Value of Unsummarized Bills</p>
                  <div className={styles.valueBox}>14,243</div>
                </div>
                <div className={styles.infoBox}>
                  <p>Number of Unsummarized Bills</p>
                  <div className={styles.valueBox}>3</div>
                </div>
              </div> */}
              <div className={styles.summaryButtons}>
                {summaries.map(summary => (
                  <div
                    key={summary._id}
                    className={styles.summaryButton}
                    onClick={() => setSelectedSummary(summary)}
                  >
                    <p>{summary.summaryName}</p>
                    <p className={styles.status}>{getSummaryStatus(summary)}</p>
                    <div className={styles.amount}><span>{summary.totalAmountOfOrders}</span>{summary.orderCount}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
      }
        {selectedSummary && (
          <SummaryAllocationModal
            summary={selectedSummary}
            deliveryBoys={deliveryBoys}
            onAllocate={handleAllocate}
            onDispatch={handleDispatch}
            onClose={() => setSelectedSummary(null)}
          />
        )}
      </>
      }
    </>
  );
};

export default SummaryTemp;


