import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './OrderReturnModal.module.css';
import { PAYMENTS } from '../../enums/routes';
import GlobalLoader from '../../Assets/Loader/GlobalLoader';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';

const OrderReturnModal = ({ buttonHeading, onClose, orderDetails }) => {
    const [shopImage, setShopImage] = useState(null);
    //const token = useSelector((state) => state.supervisor.token);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Handle file upload
    const handleFileChange = (e) => {
        setShopImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('brandOrderId', orderDetails.item.brandOrderId);
        formData.append('paymentMethod', 'fullCancel');
        formData.append('shopImage', shopImage);

        if (buttonHeading === 'Shopkeeper Denied') {
            formData.append('shopKeeperDenied', true);
        } else if (buttonHeading === 'Shop Could Not Be Attempted') {
            formData.append('shopCouldNotBeAttempted', true);
        }

        try {
            // Make the PUT request using fetch
            const token = localStorage.getItem('token');
            const response = await fetch(PAYMENTS, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                toast.success('Form submitted successfully!');
                onClose();
                navigate('/');
            } else {
                toast.error('Error submitting form. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if(loading){
        return <GlobalLoader />
    }
    return (
        <div className={classes.modalBackdrop}>
            <div className={classes.modalContent}>
                <button className={classes.backButton} onClick={onClose}>Back</button>
                <h2 className={classes.heading}>{buttonHeading}</h2>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <label className={classes.checkboxContainer}>
                        <input
                            type="checkbox"
                            required
                        />
                        Confirm Amount: {orderDetails.item.billAmount}
                    </label>

                    {/* <label className={classes.checkboxContainer}>
                        <input
                            type="checkbox"
                            required
                            onChange={() => setShopCouldNotBeAttempted(!shopCouldNotBeAttempted)}
                            disabled={buttonHeading !== 'Shop Could Not Be Attempted'}
                        />
                        {buttonHeading}
                    </label> */}

                    <button type="button" className={classes.button}>Call Salesman</button>
                    <button type="button" className={classes.button}>Call Supervisor</button>

                    <label className={classes.fileUpload}>
                        Upload Shop Pic
                        <input type="file" className={classes.fileInput} capture='environment' onChange={handleFileChange} />
                    </label>

                    <button type="submit" className={classes.submitButton}>Submit</button>
                </form>
            </div>
        </div>
    );
};

export default OrderReturnModal;
