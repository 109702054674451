import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import Modal from './Modal/Modal';
import classes from '../Assets/CSS/MoneyList.module.css';
import GlobalLoader from '../Assets/Loader/GlobalLoader';
import { fetchMoneyCartData } from '../http/https';
import { toast } from 'react-toastify';

const MoneyList = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [paymentMode, setPaymentMode] = useState('');
    const role = localStorage.getItem('role');
    const selectedSummary = useSelector((state) => state.supervisor.selectedSummary);

    const token = localStorage.getItem('token');

    const { data, isLoading, error } = useQuery({
        queryKey: ['moneyCartData', role, token, selectedSummary],
        queryFn: () => fetchMoneyCartData(role, token, selectedSummary),
        onError: (err) => {
            toast.error('Error fetching money cart data:', err);
        },
    });

    const handleOpenModal = (mode) => {
        setModalOpen(true);
        setPaymentMode(mode);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    if (isLoading) return <GlobalLoader />;
    if (error) return <h3 className={classes.error}>Failed to load data!</h3>;
    if (!data) return <h3 className={classes.error}>No Data Found!</h3>;

    return (
        <div className={classes.moneylist}>
            <div className={classes.tablecontainer}>
                <table>
                    <thead>
                        <tr>
                            <th>Mode</th>
                            <th>Amount</th>
                            <th>Bills</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cash</td>
                            <td>{data.totalCashAmount}</td>
                            <td>
                                <button className={classes.billsbutton} onClick={() => handleOpenModal('cash')}>
                                    {data.cashOrders}
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Online</td>
                            <td>{data.totalOnlineAmount}</td>
                            <td>
                                <button className={classes.billsbutton} onClick={() => handleOpenModal('online')}>
                                    {data.onlineOrders}
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Credit</td>
                            <td>{data.totalCreditAmount}</td>
                            <td>
                                <button className={classes.billsbutton} onClick={() => handleOpenModal('credit')}>
                                    {data.creditOrders}
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Cheque</td>
                            <td>{data.totalChequeAmount}</td>
                            <td>
                                <button className={classes.billsbutton} onClick={() => handleOpenModal('cheque')}>
                                    {data.chequeOrders}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Modal
                    isOpen={modalOpen}
                    onClose={handleCloseModal}
                    paymentMode={paymentMode}
                    orderDetails={data}
                    role={role}
                />
            </div>
        </div>
    );
};

export default MoneyList;
