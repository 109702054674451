import React, { useState } from 'react';
import classes from './ShopListTab.module.css';
import ShopList from '../ShopList';
import ShopHeader from '../ShopHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setShoplistAccepted, setItemlistAccepted } from '../../store/deliveryExecutive-slice';
import { ToastContainer, toast } from 'react-toastify';
import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchShopData ,handleAccept} from '../../http/https';
import GlobalLoader from '../../Assets/Loader/GlobalLoader';
const ShopListTab = () => {
    const [activeTab, setActiveTab] = useState('shoplist');
    const dispatch = useDispatch();
    const token = useSelector((state) => state.deliveryBoy.token);
    const { isShoplistAccepted, isItemlistAccepted } = useSelector((state) => state.deliveryBoy.summary);

    // Fetch shop data
    const { data, error, isLoading } = useQuery({
        queryKey: ['fetchShopData'],
        queryFn: () => fetchShopData(token),
    });

    const shopData = data?.shopList || [];
    const itemData = data?.itemList || [];
    const uuid = data?.uuid || '';

    // Mutation to handle accept actions
    const mutation = useMutation({
        mutationFn: ({ listType }) => handleAccept({ listType, uuid, token }),
        onSuccess: (message) => {
            toast.success(message);
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });

    const handleAcceptClick = (listType) => {
        if (listType === 'shoplist' && !isItemlistAccepted) {
            toast.error('Itemlist not accepted yet.');
        } else if (listType === 'itemlist' && !isShoplistAccepted) {
            toast.error('Shoplist not accepted yet.');
        } else {
            mutation.mutate({ listType });
        }
    };

    if (isLoading) {
        return <GlobalLoader />;
    }

    if (error) {
        return <div className={classes.error}>Error: {error.message}</div>;
    }

    return (
        <>
            <ToastContainer />
            <div className={classes.tabContainer}>
                <div className={classes.tabHeader}>
                    <div
                        className={`${classes.tab} ${activeTab === 'shoplist' ? classes.active : ''}`}
                        onClick={() => setActiveTab('shoplist')}
                    >
                        Shoplist
                    </div>
                    <div
                        className={`${classes.tab} ${activeTab === 'itemlist' ? classes.active : ''}`}
                        onClick={() => setActiveTab('itemlist')}
                    >
                        Item List
                    </div>
                </div>
                <div className={classes.tabContent}>
                    {activeTab === 'shoplist' &&
                        <>
                            <ShopHeader type="shop" />
                            <ShopList type="shop" data={shopData} />
                            {!isShoplistAccepted && shopData.length !== 0 && <button
                                className={classes.actionButton}
                                onClick={() => {
                                    dispatch(setShoplistAccepted(true));
                                    handleAcceptClick('shoplist');
                                }}
                            >
                                Accept Shoplist
                            </button>}
                        </>
                    }
                    {activeTab === 'itemlist' &&
                        <>
                            <ShopHeader type="item" />
                            <ShopList type="item" data={itemData} />
                            {!isItemlistAccepted && itemData.length !== 0 && <button
                                className={classes.actionButton}
                                onClick={() => {
                                    dispatch(setItemlistAccepted(true));
                                    handleAcceptClick('itemlist');
                                }}
                            >
                                Accept Itemlist
                            </button>}
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default ShopListTab;